import React from 'react';
import Layout from 'components/layout';
import { ArticleData, JourneyStep } from 'models';
import { PageProps } from 'gatsby';
import Article from '@/components/article';

function getStrapiURL(path = ``, requestParams = {}) {
  const inlinedParams = `?${Object.entries(requestParams).map(
    (elt) => `${elt[0]}=${elt[1]}&`,
  )}`;

  return `${
    process.env.NEXT_PUBLIC_STRAPI_URL ||
    `https://motorbikepacker-strapi.stg.uwizy.com`
  }${path}${inlinedParams}`;
}

// Helper to make GET requests to Strapi
const fetchAPI = async (path: string, params = {}) => {
  const requestUrl = getStrapiURL(path, params);
  const response = await fetch(requestUrl);
  const data = await response.json();
  return data;
};

interface ArticlePageProps {
  article: ArticleData;
  journeySteps: JourneyStep[];
}
const PreviewPage: React.FC<PageProps<ArticlePageProps>> = ({ location }) => {
  const [article, setArticle] = React.useState<ArticleData | undefined>();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const articleSlug = params.get(`slug`);
    if (articleSlug) {
      fetchAPI(`/articles`, { slug: articleSlug }).then((res) => {
        if (res && res[0]) {
          setArticle(res[0]);
        }
      });
    }
  }, [location]);

  return (
    <Layout expand={false}>{article && <Article article={article} />}</Layout>
  );
};

export default PreviewPage;
